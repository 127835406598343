@media (max-width: 1200px) {
  ._popUp {
    overflow: auto;
    bottom: 0;
    ._img {
      text-align: center;
    }
    ._txt {
      padding: 3.125rem 1.87rem;
    }
    .___form {
      padding: 3.125rem 1.87rem;
    }
  }
}
@media (max-width: 1199px) and (min-width: 992px) {
  ._service img {
    height: 14.06rem;
  }
}
@media (max-width: 991px){
  .navbar-light {
    .navbar-toggler {
      border: 0;
      outline: none;
    }
  }
  ._banner {
    ._img {
      margin-bottom: 1.87rem;
      img {
        width: 100%;
      }
    }
    ._txt {
      margin-bottom: 1.87rem;
    }
  }
  ._hands {
    ._txt {
      margin-bottom: 2.5rem;
      text-align: center;
    }
  }
  .navbar {
    .navbar-nav {
      li.menu-item-has-children {
        ul.sub-menu {
          position: relative;
          box-shadow: none;
          .menu-item-has-children {
            & > a {
              &:after {
                content: "\f107";
                float: none;
              }
            }
            .sub-menu {
              left: 0;
              padding-left: 1rem;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 991px) and (min-width: 768px) {

}
@media (min-width: 768px){

}
@media (max-width: 767px) {
  ._banner {
    ._img {
      margin-left: -15px;
      margin-right: -15px;
    }
  }
  ._splash {
    ._adj {
      margin-bottom: 1.87rem;
    }
  }
  ._topBar {
    ul {
      li:nth-child(1),
      li:nth-child(2) {
        display: none;
      }
    }
  }
  ._iForm {
    ._form {
      padding-top: 2.5rem;
      padding-bottom: 2.5rem;
    }
  }
  footer {
    padding-left: 0.93rem;
    padding-right: 0.93rem;
    ._logo,
    ._widget,
    ._map {
      margin-bottom: 2rem;
    }
  }
  ._abBanner {
    ._txt {
      padding: 2rem;
    }
  }
  ._value, ._admit ._txt  {
    margin-bottom: 1.87rem;
  }
  ._txtBlock {
    blockquote {
      margin-left: 0;
    }
  }
  ._serviceBlock {
    h2 {
      br {
        display: none;
      }
    }
  }
  ._faq {
    h2 {
      br {
        display: none;
      }
    }
  }
  ._share {
    ul {
      li {
        margin: 3px;
        width: 48%;
        a {
          width: 100%;
        }
      }
    }
  }
  ._cta ._txt,
  ._abBanner._adj ._txt,
  ._postBanner ._txt {
    padding: 2.5rem 1.875rem;
  }
  ._splash ._splashSection,
  ._banner ._txt,
  ._area, ._hands,
  ._testimonials,
  ._blog, ._admit,
  ._simpleTextBlock,
  ._simpleBanner, ._txtBlock,
  ._txtBanner, ._faq,
  ._serviceBlock {
    padding-left: 0.93rem;
    padding-right: 0.93rem;
  }
  ._smallBanner ._txt {
    padding-left: 1.875rem;
    padding-right: 1.875rem;
  }
}